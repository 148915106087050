// Dashboard Route
export const tradeTariffDasboardRoute = '/configuration/dashboard'
// Master Country Info Routes......
const countryInfo = '/configuration/country-info/'
export const countryInfoList = countryInfo + 'list'
export const countryInfoStore = countryInfo + 'store'
export const countryInfoUpdate = countryInfo + 'update'
export const countryInfoToggle = countryInfo + 'toggle-status'

// Master application-type Routes......
const applicationType = '/configuration/application-type/'
export const applicationTypeList = applicationType + 'list'
export const applicationTypeStore = applicationType + 'store'
export const applicationTypeUpdate = applicationType + 'update'
export const applicationTypeToggle = applicationType + 'toggle-status'

// Master Capital Info Routes......
const capitalInfo = '/configuration/capital-info/'
export const capitalInfoList = capitalInfo + 'list'
export const capitalInfoStore = capitalInfo + 'store'
export const capitalInfoUpdate = capitalInfo + 'update'
export const capitalInfoToggle = capitalInfo + 'toggle-status'

// Master Port Info Routes......
const portInfo = '/configuration/port-info/'
export const portInfoList = portInfo + 'list'
export const portInfoStore = portInfo + 'store'
export const portInfoUpdate = portInfo + 'update'
export const portInfoToggle = portInfo + 'toggle-status'

// Currency Routes......
const currency = '/configuration/currency/'
export const currencyList = currency + 'list'
export const currencyStore = currency + 'store'
export const currencyUpdate = currency + 'update'
export const currencyToggle = currency + 'toggle-status'

// Product Routes......
const Product = '/configuration/product/'
export const ProductList = Product + 'list'
export const ProductStore = Product + 'store'
export const ProductUpdate = Product + 'update'
export const ProductToggle = Product + 'toggle-status'

// Unit Measurement Routes......
const unitMeasurement = '/configuration/unit-measurement/'
export const unitMeasurementList = unitMeasurement + 'list'
export const unitMeasurementStore = unitMeasurement + 'store'
export const unitMeasurementUpdate = unitMeasurement + 'update'
export const unitMeasurementToggle = unitMeasurement + 'toggle-status'

// Sectiont Routes......
const section = '/configuration/section/'
export const sectionList = section + 'list'
export const sectionStore = section + 'store'
export const sectionUpdate = section + 'update'
export const sectionToggle = section + 'toggle-status'

// Chapter Routes......
const chapter = '/configuration/chapter/'
export const chapterList = chapter + 'list'
export const chapterStore = chapter + 'store'
export const chapterUpdate = chapter + 'update'
export const chapterToggle = chapter + 'toggle-status'

// Heading Routes......
const heading = '/configuration/heading/'
export const headingList = heading + 'list'
export const headingStore = heading + 'store'
export const headingUpdate = heading + 'update'
export const headingToggle = heading + 'toggle-status'

// Heading Routes......
const subjectInfo = '/configuration/subject-info/'
export const subjectInfoList = subjectInfo + 'list'
export const subjectInfoStore = subjectInfo + 'store'
export const subjectInfoUpdate = subjectInfo + 'update'
export const subjectInfoToggle = subjectInfo + 'toggle-status'

// Sub Heading Routes......
const subHeading = '/configuration/sub-heading/'
export const subHeadingList = subHeading + 'list'
export const subHeadingStore = subHeading + 'store'
export const subHeadingUpdate = subHeading + 'update'
export const subHeadingToggle = subHeading + 'toggle-status'

// Region Routes......
const region = '/configuration/region/'
export const regionList = region + 'list'
export const regionStore = region + 'store'
export const regionUpdate = region + 'update'
export const regionToggle = region + 'toggle-status'

// Comment Routes......
const comment = '/configuration/comments/'
export const commentList = comment + 'list'
export const commentStore = comment + 'store'
export const commentUpdate = comment + 'update'
export const commentToggle = comment + 'toggle-status'

// Comment Routes......
const rating = '/configuration/ratings/'
export const ratingList = rating + 'list'
export const ratingStore = rating + 'store'
export const ratingUpdate = rating + 'update'
export const ratingToggle = rating + 'toggle-status'

// Master Report Heading Routes......
const reportHead = '/configuration/report-heading/'
export const reportHeadList = reportHead + 'list'
export const reportHeadStore = reportHead + 'store'
export const reportHeadUpdate = reportHead + 'update'
export const reportHeadToggle = reportHead + 'toggle-status'
